export const serviceTypes = [
    { value: 'Sop/Lor/Essay', label: 'Sop/Lor/Essay' },
    {
        value: 'Dissertation/Research Paper/Thesis',
        label: 'Dissertation/Research Paper/Thesis',
    },
    {
        value: 'Linkedin/Resume/Cover Letters',
        label: 'Linkedin/Resume/Cover Letters',
    },
    {
        value: 'Script Writing/Book Writing/Ghostwriting',
        label: 'Script Writing/Book Writing/Ghostwriting',
    },
    { value: 'Other', label: 'Other' },
]