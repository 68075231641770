import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import FormContactUs from '@/Components/ContactUs/Form'
import { Helmet } from 'react-helmet'

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | Estorytellers</title>
        <meta
          name="description"
          content="Writing professional, creative, and engaging content is not an easy task. Contact our expert if you have any questions about our content writing service."
        />
        <meta
        property="og:title"
        content="Contact Us | Estorytellers"
        />
        <meta
        property="og:description"
        content="Writing professional, creative, and engaging content is not an easy task. Contact our expert if you have any questions about our content writing service."
        />
      </Helmet>
      <Header />
      <FormContactUs />
      <Footer />
    </>
  )
}
export default ContactUs
