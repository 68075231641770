import Header from '@/Components/Header'
import HomeHeroSection from '@/Components/Home/HeroSection'
import MakeDifference from '@/Components/Home/MakeDifference'
import AuthorHome from '@/Components/Home/Author'
import ClientsHome from '@/Components/Home/Clients'
import ProcessHome from '@/Components/Home/Process'
import Packages from '@/Components/Home/Packages'
import RecentBlogs from '@/Components/Home/RecentBlogs'
import Newsletter from '@/Components/Home/Newsletter'
import Footer from '@/Components/Footer'
import { Helmet } from 'react-helmet'

const ClientData = [
    {
        text: '“Estorytellers are well known for Ghostwriting. I thought to give them a shot. Overall, worth the hype. Keep it up! - Michael Schäffer ”',
        color: '#FFF',
    },
    {
        text: '“The best Ghostwriting services in India. I called them to write a book for me. They not only supported me for writing, but also helped me with publishing. I am really satisfied with their services! - Uma Swaminathan\n”',
        color: '#E6EAFF',
    },
    {
        text: '“Remarkable ghostwriters at affordable rates, without worrying about any kind of problems with delivery and quality. That\'s what I would say about my experience with Estorytellers. - Xi Mang Po”',
        color: '#DAE0FF',
    },
    {
        text: '“I like the honesty of Kritika Kanodia, CEO of Estorytellers. She also explained the whole process with ease. Project is currently, ongoing, and as of now, I am truly satisfied with their writers, delivery, and customer service. - Sakina Nazir\n”',
        color: '#CED6FF',
    }, {
        text: '“They helped me realize my idea into a beautifully-curated book. I guess, if I hadn\'t approached you, I wouldn\'t ever be able to do it myself. Thank you so much! - Harpreet Kaur”',
        color: '#CED6FF',
    },
]
const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Professional Ghostwriting Services | Ghostwriting Company</title>
        <meta
          name="description"
          content="Discover professional ghostwriting services by Estorytellers. Expert assistance to bring your ideas to life with high-quality, personalized writing solutions."
        />
        <meta
        property="og:title"
        content="Professional Ghostwriting Services | Ghostwriting Company"
        />
        <meta
        property="og:description"
        content="Discover professional ghostwriting services by Estorytellers. Expert assistance to bring your ideas to life with high-quality, personalized writing solutions."
        />
      </Helmet>
      <Header />
      <HomeHeroSection />
      {/* <BookTypes /> */}
      <MakeDifference />
      <AuthorHome />
      <ClientsHome viewPointHeight Data={ClientData} cardColor={'#fff'}/>
      <ProcessHome />
      <Packages />
      <RecentBlogs />
      <Newsletter />
      <Footer />
    </>
  )
}
export default HomePage
