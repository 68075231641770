import { Helmet } from 'react-helmet'
import styles from '@/Styles/genre.module.scss'
import { Link } from 'react-router-dom'
import Container from '@/Components/Shared/Container'
import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import Newsletter from "@/Components/Home/Newsletter";
const BookPublishing = () => {
  return (
    <>
      <Helmet>
        <title>Book Publishing Services by Estorytellers</title>
        <meta
          name="description"
          content="Comprehensive book publishing services by Estorytellers. From editing to distribution, we guide you through every step to bring your book to the world."
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <Container>
            <div className={styles.breadcrumb}>
              <Link to={'/'}> Home</Link>
              <img src="/images/bread-arrow.svg" alt="" />
              <Link to={'/service/fictional'}>Services</Link>
              <img src="/images/bread-arrow.svg" alt="" />
              <span>Book Publishing</span>
            </div>
            <img
              src="/images/fictional_offer_1.svg"
              alt=""
              className={styles.icon}
            />
            
            <h1>Your Story, Our Expertise – Presenting Estorytellers’ Book Publishing Services</h1>
            <p>From conceptualisation to completion, our book publishing services provide the professional guidance, resources, and expertise to transform your manuscript into a polished, published book. With a full suite of publishing solutions, we’re here to make the journey as seamless and rewarding as possible.</p>
          </Container>
        </div>
        <Container>
            <div className={styles.content}>
                <h2>Our End-To-End Book Publishing Solutions</h2>
                <p>Publishing a book involves many steps—from editing and design to marketing and distribution. Our team is dedicated to providing end-to-end solutions that cover each stage of the publishing process:</p>
                <h3>1. Manuscript Evaluation &amp; Developmental Editing</h3>
                <p>Our process begins by carefully evaluating your manuscript. Our editors have over 15 years of experience and provide detailed feedback to help you strengthen your story’s structure, character development, pacing, and overall narrative flow. Through our developmental editing services, we work closely with you to enhance your story while preserving your unique voice.</p>
                <h3>2. Professional Copyediting &amp; Proofreading</h3>
                <p>Ensuring your manuscript is polished and error-free is essential to building reader trust. Our professional editors meticulously refine grammar, syntax, punctuation, plagiarism, and style, while our proofreaders offer a final review to eliminate any lingering typos and inconsistencies.</p>
                <h3>3. Book Design &amp; Formatting</h3>
                <p>Visual appeal matters just as much as content. Our designers create custom cover designs that capture your book’s essence and appeal to your target audience. We also handle the internal formatting, ensuring a smooth reading experience, whether in print or digital format.</p>
                <h3>4. Print-On-Demand &amp; Ebook Publishing</h3>
                <p>Whether you want your book on bookstore shelves or accessible online, we offer flexible print-on-demand and ebook publishing services. </p>
                <p>This approach reduces printing costs, allowing you to reach readers worldwide without an upfront inventory. </p>
                <p>Our team also handles the formatting necessary for digital platforms to ensure compatibility with all major ebook readers.</p>
                <h3>5. Global PR That Puts You In The Spotlight</h3>
                <p>Stand out in the crowded marketplace with a PR push that features your book on over 100 domestic and international platforms, including prestigious names like Forbes and more.</p>
                <h3>6. ISBN Registration &amp; Copyright Services</h3>
                <p>Protecting your work is essential. We handle ISBN registration, ensuring your book is uniquely identified for distribution across sales channels. </p>
                <p>Additionally, our copyright services give you peace of mind, safeguarding your creative rights and intellectual property.</p>
                <h3>7. Keep 100% Of Your Royalties</h3>
                <p>Your hard work should bring you complete rewards. That’s why we ensure you retain 100% of your royalties—no hidden fees or deductions.</p>
                <h3>8. Global Distribution &amp; Bookseller Placement</h3>
                <p>Our distribution network spans major online retailers and bookstores, allowing your book to reach readers everywhere. </p>
                <p>From Amazon to regional bookshops, our partnerships give you the exposure needed to build your audience.</p>
                <h3>9. Marketing &amp; Book Promotion</h3>
                <p>Getting published is just the beginning; reaching readers requires targeted marketing. We create tailored marketing strategies, including social media promotions, email marketing, and book launch support. Our experienced team helps you generate buzz and build connections with your readers.</p>
                <h2>Why Choose Estorytellers For Book Publishing?</h2>
                <p>At Estorytellers, we are passionate about supporting authors through every step of the publishing journey. Our team brings years of industry expertise and a genuine commitment to helping you achieve your vision. </p>
                <p>We know each book is unique, and our services are flexible to fit the specific needs of both new and seasoned authors.</p>
                <p>From crafting a visually stunning cover to promoting your book after publication, our team is here to ensure your story reaches the world with the quality and professionalism it deserves.</p>
                <h2>Start Publishing With Confidence And Reach Wide Audience</h2>
                <p>Share your story with us, and let’s bring it to the pages and screens of readers everywhere with our book publishing services. </p>
                <p>Your book is set to make an impression with Estorytellers’ complete book writing, marketing, and publishing services.</p>
            </div>
        </Container>
        <Newsletter />
      </div>
      <Footer />
    </>
  )
}
export default BookPublishing
